
/* Top bar */
.top-bar { 
  background-color: $color-background-accent;
  background-color: var(--color-background-accent);
  border-bottom: solid 1px rgba(0,0,0,.25);
  display: grid;
  grid-template-columns: max-content 1fr;
  padding: 0.5rem 2vw 0;
  position: fixed;
  z-index: 98;
  position: fixed;
  top: 0;
  width: 100vw;
*:last-child {
    justify-self: right;
  }
}

.page-title {
  display: block;
  padding-bottom: 0.5rem;
  font-weight: 600;
}

/* Navigation */

.title-link {
  display: block;
  font-weight: 600;
  @supports (font-variation-settings: normal) {
    font-weight: 625;
    .wf-inactive & {
      font-weight: bold;
    }
  }
  &:link,
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
  &:active {
    text-decoration: underline;
  }
  @media (pointer: coarse) {
    &:hover {
      text-decoration: unset;
    }
  }
}
.link-wrapper {
  display: flex;
}

.page-mask {
  background-color: rgba(0,0,0,0);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;
  transition: all 0.1s ease-in;
  &.active {
    background-color: rgba(0,0,0,.75);
    z-index: 90;
  }
}

.dd-menu {
  --text-opsz: 10;
  display: inline-block;
  font-family: $fonts-serif-static-caption;
  font-family: var(--fonts-serif-static-caption);
  position: relative;
  @supports (font-variation-settings: normal) {
    font-family: var(--fonts-serif);
    font-variation-settings: 'opsz' var(--text-opsz);
    .wf-inactive & {
      font-family: var(--fonts-base-serif);
      font-size: 1.1em;
    }
  }
  .menu {
    font-size: 0.92rem;
    height: 1px;
    list-style: none;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0.25rem;
    position: absolute;
    text-align: left;
    top: 2rem;
    transition: opacity 0.2s ease-in, height 0.1s ease-in;
    left: 0;
    width: 100%;
    li {
      border-bottom: solid 1px rgba(0,0,0,.25);
      &:last-child {
        border-bottom: none;
      }
      &.active {
        padding: 0.5em 0.15em;
      }
    }

    a {
      display: block;
      font-size: 0.875rem;
      padding: 0.5em 0.15em;
      .wf-inactive & {
        font-size: 0.9rem;
        padding: 0.475em 0.15em;
      }

      &:link {
        text-decoration: none;
      }
      
      &:visited {
        text-decoration: none;
      }
      
      html:not(.touch-nav) &:hover {
        background-color: rgba(0,0,0,.05);
        text-decoration: none;
      }
      
      &:focus {
        background-color: rgba(0,0,0,.05);
        text-decoration: none;
      }
      
      &:active {
        background-color: rgba(0,0,0,.05);
        text-decoration: none;
      }
    }
  }
  &-trigger {
    display: block;
    height: 2rem;
    text-align: right;
    &:link,
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      text-decoration: underline;
    }
    &:active {
      text-decoration: underline;
    }
    @media (pointer: coarse) {
      &:hover {
        text-decoration: unset;
      }
    }
  }
  html:not(.touch-nav) &:focus-within,
  html:not(.touch-nav) &:hover,
  &.open {
    .menu {
      background-color: $color-background-accent;
      background-color: var(--color-background-accent);
      border-left: solid 1px rgba(0,0,0,.25);
      box-shadow: 0.1em 0.1em 0.2em #eaeaea;
      min-height: 8rem;
      opacity: 1;
      overflow-y: scroll;
      z-index: 99;
    }
  }
}

.settings {
  min-width: 5rem;
  text-align: right;
  @media screen and (min-width: $bp--medium) {
    min-width: 7rem;
  }
}

.toc {
  .dd-menu-trigger {
    min-width: 11rem;
    text-align: right;
    @media screen and (min-width: $bp--medium) {
      min-width: 15rem;
      &::before {
        content: "Table of ";
      }
    }
  }
  &:focus-within,
  &:hover {
    .menu {
      height: 35vh;
      min-height: 10rem;
    }
  }
}

/* Settings */
.toggle-swipe {
  &.on:after {
    content: ": on";
  }
  &.off:after {
    content: ": off";
  }
}



/* Chapter prev/next navigation */
.prev-next-chapters {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  list-style: none;
  margin: 0 auto;
  margin: calc(var(--body-text-spacer) * 2) 0 0 0;
  max-width: var(--content-width);
  padding: 0;

  @media screen and (min-width: $bp--small) {
    flex-direction: row;
  }

  > * {
    display: block;
    flex-grow: 1;
    margin-bottom: 1rem;
    margin-bottom: var(--body-text-spacer);
  }

  .previous {
    text-align: left;
  }

  .next {
    text-align: right;
  }

  a {
    &:link,
    &:visited {
      --text-opsz: 10;
      font-family: $fonts-serif-static-caption;
      font-family: var(--fonts-serif-static-caption);
      font-size: 0.875rem;
      text-decoration: none;
      @supports (font-variation-settings: normal) {
        font-family: var(--fonts-serif);
        font-variation-settings: 'opsz' var(--text-opsz);
        .wf-inactive & {
          font-family: var(--fonts-base-serif);
        }
      }
      @media screen and (min-width: $bp--large) {
        font-size: 1rem;
      }
    }
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
    @media (pointer: coarse) {
      &:hover {
        text-decoration: unset;
      }
    }
  }
}

/* Book title */
.publication-title {
  font-weight: 900;
}

/* Editor's note */
.note {
  background-color: #ffffff;
  border: solid 1px #eaeaea;
  max-width: var(--content-width);
  padding: 0.5rem;
  figure + & {
    margin-top: 1rem;
    margin-top: var(--body-text-spacer);
  }
  > *:first-child {
    margin-top: 0;
  }
}

figure {
  //margin: 0;
  padding: 0;
  max-width: $content-width-s;
  max-width: var(--content-width);
  width: 100%;
  @media screen and (min-width: $bp--medium) {
    max-width: $content-width-m;
    max-width: var(--content-width);
  }
  @media screen and (min-width: $bp--large) {
    max-width: $content-width-l;
    max-width: var(--content-width);
  }

  img {
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    padding: 0;
    width: auto;
  }

  &.wide {
    margin-left: calc( var(--margin-left) / 2 );
    margin-right: calc( var(--margin-left) / 2 );
    max-width: calc( 100vw - var(--margin-left) );
    width: 100%;
  }

  &.small {
    --figure-width: calc( var(--content-width) / 3);
    --centering-margin: calc( var(--content-width) / 3);
    width: var(--figure-width);
  }

  &.medium {
    --figure-width: calc( var(--content-width) / 2);
    --centering-margin: calc( var(--content-width) / 4);
    width: var(--figure-width);
  }

  &.large {
    max-width: var(--content-width);
    width: 100%;
  }

  &.center {
    margin-left: calc( var(--margin-left) + var(--centering-margin) );
  }

  &.left {
    clear: left;
    float: left;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    margin-bottom: var(--body-text-spacer);
  }

  &.right {
    clear: right;
    float: right;
    margin-left: 1.5rem;
    margin-right: var(--margin-right);
    margin-bottom: 1rem;
    margin-bottom: var(--body-text-spacer);
  }

  * + & {
    margin-top: 1rem;
    margin-top: var(--body-text-spacer);
  }
}

/* Blockquote styles */
blockquote {
  margin-bottom: var(--body-text-spacer);
  * + & {
    margin-top: var(--body-text-spacer);
  }
  p {
    font-size: $bq-size-s;
    font-size: var(--bq-size-s);
    font-style: italic;
    font-variant-ligatures: common-ligatures discretionary-ligatures;
    font-weight: 300;
    .wf-inactive & {
      letter-spacing: 0;
      word-spacing: 0.05em;
      em {
        letter-spacing: -0.0025em;
        word-spacing: -0.01em;
      }
    }
      @media screen and (min-width: $bp--medium) {
      font-size: $bq-size-m;
      font-size: var(--bq-size-m);
    }
    @media screen and (min-width: $bp--large) {
      font-size: $bq-size-l;
      font-size: var(--bq-size-l);
    }
    @supports (font-size: clamp(1rem, 2vw, 3rem)) {
      /* responsive typography scaling bits */
      --font-size-min-value: var(--bq-size-s-value);
      --font-size-max-value: var(--bq-size-l-value);
      --font-size-scaler-value: var(--bq-scaler-value);
      font-size: var(--font-size);
  
      --font-lh-min-value: var(--bq-lh-s);
      --font-lh-max-value: var(--bq-lh-l);
      --font-lh-scaler-value: var(--bq-lh-scaler-value);
      line-height: var(--line-height); 
    }
    &:before {
      content: '“';
      margin-left: -0.525em;
      margin-right: 0.1em;
    }
    &:after {
      content: '”';
    }
  }
  &.right {
    @media screen and (min-width: $bp--large) {
      float: right;
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      padding-left: 2rem;
      width: calc(10vw + var(--margin-right));
    }
    @media screen and (min-width: $bp--xlarge) {
      width: var(--margin-right);
    }
  }
  cite {
    font-size: $p-size-s;
    font-size: var(--p-size-s);
    font-style: italic;
    @media screen and (min-width: $bp--medium) {
      font-size: $p-size-m;
      font-size: var(--p-size-m);
    }
    @media screen and (min-width: $bp--large) {
      font-size: $p-size-l;
      font-size: var(--p-size-l);
    }
    @supports (font-size: clamp(1rem, 2vw, 3rem)) {
      /* responsive typography scaling bits */
      --font-size-min-value: var(--p-size-s-value);
      --font-size-max-value: var(--p-size-l-value);
      --font-size-scaler-value: var(--p-scaler-value);
      font-size: var(--font-size);
  
      --font-lh-min-value: var(--p-lh-s);
      --font-lh-max-value: var(--p-lh-l);
      --font-lh-scaler-value: var(--p-lh-scaler-value);
      line-height: var(--line-height); 
    }
    &:before {
      content: "—";
    }
  }
}

.lang-table {
  list-style: none;
  padding: 0;
  .chars {
    display: inline-block;
    width: 13.5rem;
  }
  .language {
    display: inline-block;
    font-style: italic;
  }
}

article {
  > h1 {
    @media screen and (min-width: $bp--medium) {
      float: left;
      margin-left: calc( var(--margin-left) / 2 );
      margin-right: auto;
      max-width: 60vw;
    }
  }
  > h1 + h2 {
    @media screen and (min-width: $bp--medium) {
      clear: left;
      margin-left: calc( var(--margin-left) / 2 );
      margin-right: calc( var(--margin-left) / 2 );
      max-width: calc( 100vw - var(--margin-left) );
    }
  }
  figure + h1 {
    margin-top: 0.25rem;
    margin-top: calc( var(--body-text-spacer) * .25 );
  }
}

/* Footer */
body > footer {
  border-top: solid 1px rgba(0,0,0,0.15);
  color: $color-text;
  color: var(--color-text);
  font-size: 0.75rem;
  margin-top: 3rem;
  padding: 0.25rem 2vw 0.5rem 2vw;
  text-align: center;
  @media screen and (min-width: $bp--small) {
    font-size: 0.825rem;
    padding: 0.25rem 2vw 0.5rem var(--margin-left);
    text-align: left;
  }
  @media screen and (min-width: $bp--medium) {
    padding: 0.5rem 2vw 1rem var(--margin-left);
  }

  a {
    &:link,
    &:visited { 
      --text-opsz: 7;
      font-family: $fonts-serif-static-caption;
      font-family: var(--fonts-serif-static-caption);
      font-size: 0.625rem;
      @media screen and (min-width: $bp--small) {
        font-size: 0.75rem;
      }
      @supports (font-variation-settings: normal) {
        font-family: var(--fonts-serif);
        font-variation-settings: 'opsz' var(--text-opsz);
        .wf-inactive & {
          font-family: var(--fonts-base-serif);
          font-weight: normal;
          font-size: 0.8675rem;
        }
      }
    }
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.footnote {
  &--reference {
    &:link,
    &:visited {
      font-size: inherit;
      font-variant-numeric: lining-nums;
      font-feature-settings: 'numr' 1;
      font-weight: 500;
      line-height: inherit;
      vertical-align: inherit;
      padding-left: 0.1em;
      padding-right: 0.1em;
      text-underline-offset: -0.15em;
      scroll-padding: 2em 0 0 0;
    }
  }
}
.footnotes {
  padding-left: 1em;

  li {
    --p-size-s-value: 0.75;
    --p-size-m-value: 0.875;
    --p-size-l-value: 1;
    text-align: top;
    scroll-padding: 2em 0 0 0;
    + li {
      margin-top: calc( var(--body-text-spacer) * 1 );
    }
  }
}

.pub-date {
  margin-top: 1em;
  margin-bottom: 1em;
}

@media (prefers-color-scheme:dark) {
  html:not(.light) {
    --color-background: var(--color-dark-gray);
    --color-background-accent: var(--color-warm-dark-gray);
    --color-text: var(--color-paper-light);
    --color-a-link: var(--color-light-blue);
    --color-a-visited: var(--color-light-gray);
    --color-a-hover: #{$color-red};
    --color-a-focus: #{$color-red};
    --color-a-active: #{$color-red};
    --color-text-accent: var(--color-light-gray);
    p {
      font-weight: 350;
      letter-spacing: 0.0015em;
    }
  }
}
.dark {
  --color-background: var(--color-dark-gray);
  --color-background-accent: var(--color-warm-dark-gray);
  --color-text: var(--color-paper-light);
  --color-a-link: var(--color-light-blue);
  --color-a-visited: var(--color-light-gray);
  --color-a-hover: #{$color-red};
  --color-a-focus: #{$color-red};
  --color-a-active: #{$color-red};
  --color-text-accent: var(--color-light-gray);
  p {
    font-weight: 350;
    letter-spacing: 0.0015em;
  }
}
